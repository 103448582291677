<template>
  <div>
    <section class="section hero is-fullheight is-error-section">
      <div v-if="isHidden">
        <b-button type="is-black is-outlined" @click="goBack" v-text="$getTranslation('general.go-back')"></b-button>
      </div>
      <div class="hero-body">
        <div class="container">
          <div class="columns is-centered">
            <div class="column is-two-fifths">

              <div class="card has-card-header-background">
                <b-notification
                  v-if="error"
                  has-text-centered
                  type="is-danger"
                  aria-close-label="Close notification"
                  role="alert"
                  v-html="getErrors()"
                >
                </b-notification>
                <header class="card-header">
                  <p class="card-header-title">
                    <span class="icon"><i class="mdi mdi-lock default"></i></span>
                    <span v-text="$getTranslation('auth.reset.title')"></span>
                  </p>
                </header>

                <div class="card-content">
                  <ValidationObserver ref="observer" v-slot="{ passes }">
                  <form
                    ref="form"
                    method="POST"
                    action="/reset-password"
                    @submit.prevent="handleSubmit"
                  >
                      <div v-if="!isLoggedIn">
                        <InputWithValidation
                          horizontal
                          :rules="emailRules"
                          type="email"
                          v-model="form.email"
                          icon="email"
                          :label="$getTranslation('auth.form.email.label')"
                          :placeholder="$getTranslation('auth.form.email.placeholder')"
                        />
                      </div>
                      <div v-else>
                        <InputWithValidation
                          horizontal
                          rules="required"
                          vid="password"
                          type="password"
                          v-model="form.old_password"
                          icon="lock"
                          :label="$getTranslation('auth.form.passwordOld.label')"
                          :placeholder="$getTranslation('auth.form.password.placeholder')"
                          password-reveal
                        />
                        <input type="hidden" v-model="form.email" />
                      </div>

                      <InputWithValidation
                        horizontal
                        :rules="passwordRules"
                        vid="password"
                        type="password"
                        v-model="form.password"
                        icon="lock"
                        :label="$getTranslation('auth.form.password.label')"
                        :placeholder="$getTranslation('auth.form.password.placeholder')"
                        password-reveal
                      />

                      <InputWithValidation
                        horizontal
                        :rules="confirmRules"
                        type="password"
                        v-model="form.password_confirmation"
                        icon="lock"
                        :label="$getTranslation('auth.form.passwordConfirm.label')"
                        :placeholder="$getTranslation('auth.form.passwordConfirm.placeholder')"
                        password-reveal
                      />

                      <hr>
                      <div class="field is-grouped">
                        <div class="control">
                          <b-button
                            native-type="submit"
                            type="is-black"
                            @click="passes(submit)"
                            >
                            <span>{{ $getTranslation('form.submit') }}</span>
                          </b-button
                          >
                        </div>
                        <div class="control">
                          <b-button type="is-black is-outlined" @click="reset"
                            >{{ $getTranslation('form.reset') }}</b-button
                          >
                        </div>
                      </div>
                    </form>
                  </ValidationObserver>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { loggedInPage, ApiUsers, ApiResetPassword, ApiAcceptInvite, ApiRejectInvite, ApiProjectDetails } from '@/api-routes.js'
import { mapGetters, mapActions } from 'vuex'
import FormTemplateMixin from '@/mixins/FormTemplateMixin'

export default {
  name: 'app-reset-password',
  mixins: [FormTemplateMixin],
  data () {
    return {
      id: 0,
      method: 'post',
      token: null,
      isHidden: false,
      formObj: {
        name: null,
        email: null,
        // accepted: 0,
        old_password: null,
        password: null,
        password_confirmation: null,
      }
    }
  },
  computed: {
    ...mapGetters([
      "item"
    ]),

    emailRules () {
      return 'required|email'
    },

    confirmRules () {
      return 'required|confirmed:password'
    },

    passwordRules () {
      return 'required'
    },
  },

  methods: {
    ...mapActions([
      "setPasswords",
      "signUserOut",
      "fetchItem"
    ]),


    submit (value) {
      // console.log('accept?', value)
      this.form.token = this.token;
      let endpoint = ApiResetPassword
      // let action = 'accepted'

      let payload = {
        'endpoint': endpoint,
        'data': this.form
      }

      let message = this.$getTranslation('auth.reset.message')

      this.setPasswords(payload)
        .then(result => {
          // TODO: fix error messages - for reject is different, etc
          setTimeout(() => {
            if(!this.error) {
              this.snackError(message, 'is-success')
              // this.$router.push('/');
            }
          }, 1500);
        })
        .catch(error => {
          // console.log("catch error form:", error.message);
          this.snackError(message, 'is-danger')
        });

    },

    // this.$toast.success(message)
    snackError(message, type) {
      this.$buefy.toast.open({
        message: message,
        type: type,
        position: 'is-top',
        // actionText: 'Retry',
        indefinite: true,
        /*onAction: () => {
          this.$buefy.toast.open({
            message: 'Action pressed',
            queue: false
          })
        }*/
      })
    }
  },

  beforeMount() {
    // console.log('beforeMounted: ', this.$route)
    /*if(this.$route.meta.type === 'invitation' && this.isLoggedIn) {
      this.signUserOut();
    }*/
  },
  created () {
    // console.log('loggedin? ', this.isLoggedIn, this.$route.params.token)
    if(!this.isLoggedIn && typeof(this.$route.params.token) === 'undefined') {
      this.isHidden = true
      let message = this.$getTranslation('auth.missing-token')
      let type = 'is-danger'
      this.snackError(message, type)
    }

    // reset password for loggedIn / not loggedIn users
    if(typeof this.$route.params.token === 'undefined' && this.isLoggedIn) {
      this.form.email = this.isLoggedUser.email
      this.token = 0
    } else {
      this.token = this.$route.params.token
    }
  }
}
</script>
